import { COLORS } from 'lib/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PieChart } from 'react-minimal-pie-chart';
import React from 'react';

export default function GaugeChart({ data, options }) {
  let degree = 0;
  switch (options.level) {
    case 'sparse':
      degree = 'rotate(120, 50, 50)';
      break;
    case 'normal':
      degree = 'rotate(170, 50, 50)';
      break;
    case 'crowded':
      degree = 'rotate(220, 50, 50)';
      break;
    case 'congested':
      degree = 'rotate(270, 50, 50)';
      break;
    default:
      break;
  }

  return (
    <PieChart
      data={data}
      startAngle={180}
      lengthAngle={180}
      lineWidth={40}
      radius={47}
      background={COLORS.light}
      {...options}>
      <text
        x="10%"
        y="70%"
        textAnchor="middle"
        fill={COLORS.light}
        style={{ fontSize: 8 }}>
        여유
      </text>
      <FontAwesomeIcon id="icon" icon="map-marker-alt" symbol="marker" />
      <svg>
        <g fill="white">
          <circle
            cx={50}
            cy={50}
            r={18}
            fill={COLORS.white}
            fillOpacity="0.9"
          />
          <circle
            cx={50}
            cy={50}
            r={13}
            fill={options.fill}
            fillOpacity="0.9"
          />
          <use
            style={{ color: 'white' }}
            x={43}
            y={45}
            href="#marker"
            width={15}
            height={15}
            transform={degree}
          />
        </g>
      </svg>
      <text
        x="88%"
        y="70%"
        textAnchor="middle"
        fill={COLORS.light}
        style={{ fontSize: 8 }}>
        <tspan>매우</tspan>
        <tspan x="88%" dy={9.5}>
          혼잡
        </tspan>
      </text>
    </PieChart>
  );
}
