/* eslint-disable arrow-body-style */
import { CONGESTION_LEVELS } from 'lib/constants';
import React, { useMemo, useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { getCongestionLevel } from 'lib/utils';
import { sensors } from 'lib/config';
import chroma from 'chroma-js';
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Circle,
} from 'react-leaflet';
import L from 'leaflet';
import { BsFillCircleFill } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Loader from '../components/Loader';

export default function SensorsMap({ props }) {
  const mapRef = React.useRef();

  const [bounds, setBounds] = useState([]);
  const [loading, setLoading] = useState(true);

  const SENSORS = useMemo(
    () => Object.values(sensors).sort((a, b) => a.index - b.index),
    []
  );

  const results = useMemo(() => props, [props]);

  const resultData = useMemo(
    () =>
      results.map((result, i) => {
        const sensor = SENSORS[i];
        const data = result?.data?.data || {};
        const count = data?.count || 0;
        const lat = sensor?.lat;
        const lng = sensor?.lng;
        const max = sensor.area / CONGESTION_LEVELS.congested.distance;
        const level = getCongestionLevel(count, sensor.area);
        return {
          id: sensor.id,
          name: sensor.name,
          area: sensor.area,
          lat,
          lng,
          count,
          level,
          max,
        };
      }),
    [results]
  );

  useEffect(() => {
    let success = 0;
    results.forEach((r) => {
      success += r.isSuccess;
    });
    if (success === SENSORS.length) {
      setLoading(false);
      const b = resultData
        .filter((sensor) => sensor.lat && sensor.lng)
        .map((sensor) => [sensor.lat, sensor.lng]);
      setBounds(b);
    }
  }, [resultData]);

  if (loading) {
    return <Loader />;
  }

  if (!loading) {
    return (
      <MapContainer
        style={{
          height: '100%',
          width: '100%',
          borderRadius: '25px',
          boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.5)',
        }}
        bounds={bounds}
        zoomSnap={0.3}
        maxZoom={17.2}
        zoomControl={false}
        className="map"
        whenCreated={(map) => {
          mapRef.current = map;
        }}>
        (
        <>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {resultData.map((sensor) => {
            const { id, name, level, lat, lng, area } = sensor;

            return (
              lat !== null && (
                <Marker
                  id={id}
                  key={id}
                  position={[lat, lng]}
                  opacity={1}
                  zIndexOffset={10000}
                  icon={L.divIcon({
                    className: `w-auto`,
                    html: ReactDOMServer.renderToString(
                      <FaMapMarkerAlt
                        size={20}
                        color={chroma(level.fill).darken(1.6)}
                      />
                    ),
                  })}>
                  <Circle
                    stroke={false}
                    center={[lat, lng]}
                    pathOptions={{
                      fillColor: chroma(level.fill).darken(2),
                    }}
                    radius={area}
                  />
                  <Tooltip
                    direction="top"
                    offset={[5, -5]}
                    opacity={0.8}
                    permanent
                    style={{
                      padding: '0.2rem',
                    }}>
                    <div
                      style={{
                        minWidth: '90px',
                      }}>
                      <BsFillCircleFill
                        color={level.fill}
                        style={{ marginRight: '0.5rem' }}
                      />
                      <span style={{ fontWeight: 'bolder' }}>{name}</span>
                    </div>
                  </Tooltip>
                </Marker>
              )
            );
          })}
        </>
        );
      </MapContainer>
    );
  }
}
