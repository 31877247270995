import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import GaugeChart from 'components/GaugeChart';
import { getCongestionLevel } from 'lib/utils';
import { sensors } from 'lib/config';
import { DateTime } from 'luxon';
import Container from 'components/Container';
import { useQueries, useQuery } from 'react-query';
import { COLORS } from 'lib/theme';
import { CONGESTION_LEVELS } from 'lib/constants';
import SensorsMap from './SensorsMap';
import Wrapper from '../components/Wrapper';
import { fetchOpenWeatherApi, fetchRealtimeVisitorCount } from '../lib/api';

const MapContainer = styled.div`
  width: 100%;
  height: 98%;
  margin-top: 1rem;
  padding: 20px 20px 20px 20px;
`;

const ChartsContainer = styled.div`
  color: ${COLORS.light};

  & div.mapDiv {
    width: 65%;
    height: 100%;
    padding: 20px 20px 20px 40px;
  }

  & div.sensorInfoDiv { 
    background: ${COLORS.transparentWhite50};
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.5);
    border-radius: 25px;

  & div.gaugeChartDiv {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    text-align: center; 

     & div.time {
      font-size: 16px;
      margin-top: -1.7rem;
      padding-top: 1rem;
    }
  }

  & div.name {
    font-size: 24px;
    font-weight: bold;
    padding-top: 1.7rem;
    position: relative;
    text-align: center;
    & small {
      color: ${COLORS.transparentWhite50};
      position: absolute;
      top: 9px;
      right: 14px;
      font-size: 10px;
      font-weight: normal;
    }
  }
`;
const InfoContainer = styled.div`
  margin-top: 1rem;
  color: ${COLORS.light};

  & div.title {
    font-size: 34px;
    font-weight: bold;
    margin-top: 1rem;
    text-align: left;
  }

  & div.weather {
    align-items: center;
    background-color: ${COLORS.transparentWhite50};
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    display: grid;
    font-size: 30px;
    height: 97%;
    width: 100%;
    margin-left: 1.5rem;
    text-align: center;

    & div.icon {
      padding-top: 2rem;
    }
    & div.temp {
      font-size: 45px;
      font-weight: 600;
      padding-top: 0.8rem;
      text-align: center;
    }
    & div.date {
      padding-top: -0.4rem;
      padding-bottom: 2rem;
      font-size: 20px;
    }
  }

  & div.legends {
    background-color: ${COLORS.transparentWhite50};
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    color: ${COLORS.white};
    font-size: 24px;
    line-height: 1.9;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 15px;
    padding: 22px 30px;
  }
`;

export default function KioskMap() {
  const [weatherInfo, setWeatherInfo] = useState({});
  const [time, setTime] = useState(
    DateTime.now().setLocale('ko').toFormat('cccc, t')
  );

  const legends = useMemo(
    () => [
      CONGESTION_LEVELS.sparse,
      CONGESTION_LEVELS.normal,
      CONGESTION_LEVELS.crowded,
      CONGESTION_LEVELS.congested,
    ],
    []
  );
  const SENSORS = useMemo(
    () => Object.values(sensors).sort((a, b) => a.index - b.index),
    []
  );

  useEffect(() => {
    const interval = setInterval(
      () => setTime(DateTime.now().setLocale('ko').toFormat('cccc, t')),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  const results = useQueries(
    SENSORS.map((sensor) => ({
      queryKey: ['sensor', sensor.id],
      queryFn: fetchRealtimeVisitorCount,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }))
  );

  const resultData = useMemo(
    () =>
      results.map((result, i) => {
        const sensor = SENSORS[i];
        const data = result?.data?.data || {};
        const count = data?.count || 0;
        const max = sensor.area / CONGESTION_LEVELS.congested.distance;
        const level = getCongestionLevel(count, sensor.area);
        return {
          id: sensor.id,
          name: sensor.name,
          area: sensor.area,
          lat: sensor.latitude,
          lng: sensor.longitude,
          count,
          level,
          max,
        };
      }),
    [results]
  );

  const weatherQuery = useQuery({
    queryKey: 'openWeather',
    queryFn: fetchOpenWeatherApi,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (weatherQuery.data) {
      const { data } = weatherQuery;
      const {
        main: { temp },
        weather: [weather],
      } = data;
      setWeatherInfo({
        description: weather?.description,
        icon: weather?.icon,
        temperature: temp,
        date: DateTime.now().setLocale('ko').toFormat('dd/LLL yyyy'),
      });
    }
  }, [weatherQuery.data]);

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <MapContainer>
              <SensorsMap props={results} />
            </MapContainer>
          </Col>
          <Col>
            <InfoContainer>
              <Row className="FirstRow">
                <div className="title">
                  {process.env.REACT_APP_NAME}
                  <hr className="text-light text-center" />
                </div>
                <Col>
                  <ChartsContainer>
                    <div className="sensorInfoDiv">
                      {resultData.map((data) => {
                        const { id, area, name, count, level, max } = data;
                        return (
                          <div key={id + name} className="chart">
                            <div className="name">
                              {name}
                              <small>
                                {area} m<sup>2</sup>
                              </small>
                            </div>
                            <div className="gaugeChartDiv">
                              <GaugeChart
                                data={
                                  count
                                    ? [
                                        {
                                          title: name,
                                          value: count > max ? max : count,
                                          color: level.fill,
                                        },
                                      ]
                                    : [
                                        {
                                          title: name,
                                          value: 0,
                                        },
                                      ]
                                }
                                options={{
                                  fill: level.fill,
                                  level: level.name,
                                  totalValue: max,
                                }}
                              />
                              <div className="time">{time}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </ChartsContainer>
                </Col>
                <Col>
                  {weatherQuery.isSuccess && (
                    <div className="weather">
                      <div className="icon">
                        <i
                          className={`owi owi-fw owi-4x owi-${weatherInfo.icon}`}
                        />
                      </div>
                      <div className="temp">
                        {Math.round(weatherInfo.temperature)}°c
                      </div>
                      <div className="date">{weatherInfo.date}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <div className="legends">
                  {legends.map((legend) => (
                    <div key={legend.name}>
                      <FontAwesomeIcon
                        icon="circle"
                        color={legend.fill}
                        size="lg"
                      />
                      <span style={{ marginLeft: '0.6rem' }}>
                        {legend.label}
                      </span>
                    </div>
                  ))}
                </div>
              </Row>
            </InfoContainer>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
